import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import { hubGqlClient } from '~/libs/gql';
import { graphql } from '~/types/__generated/gql';
import { GetOrderByIdQuery } from '~/types/__generated/gql/graphql';
import {
	GetOperationalOrdersResults,
	getOperationalOrdersKey,
	initialData,
} from '../useGetOperationalOrders';
import { regroupOrders } from '../useSubscribeToVenueOrders';

const query = /* GraphQL */ `
	query GetOrderById($id: ObjectID!) {
		getOrderById(id: $id) {
			_id
			created_at
			updated_at
			index
			prepared_at
			processed_at
			paid_at
			cancelled_at
			prepared_by
			processed_by
			paid_by
			cancelled_by
			changelogs {
				created_at
				updated_at
				user
				identifier
				old_value
				new_value
				type
			}
			user
			customer
			venue
			status
			is_paid
			payway_ref
			payway_verification_data
			staff
			original_orders
			cancelled_reason
			source
			device
			prep_time
			currencies_configs
			location
			current_location
			items {
				_id
				category
				item
				title
				original_price
				original_price_addons {
					vat {
						percentage
						amount
					}
					service_charge {
						percentage
						amount
					}
				}
				unit_price
				listed_price
				quantity
				extra_quantity
				serving_quantity
				comments
				cancelled_reason
				recipe {
					raw_material
					unit_of_measurement
					consumed_unit_of_measurement
					consumed_quantity
				}
				options {
					comments
					_id
					option
					uid
					title
					quantity
					original_price
					original_price_addons {
						vat {
							percentage
							amount
						}
						service_charge {
							percentage
							amount
						}
					}
					listed_price
					recipe {
						raw_material
						unit_of_measurement
						consumed_unit_of_measurement
						consumed_quantity
					}
				}
				type
				delivered_count
				status
				prep_time
				printer_tag
				subtotal
				subtotal_addons {
					offer {
						metadata
						amount
					}
					discount {
						is_divided
						type
						value
						amount
					}
					vat {
						is_included
						percentage
						amount
					}
					service_charge {
						is_included
						percentage
						amount
					}
					adjustment {
						amount
					}
				}
				net_amount
				gross_amount
				cancelled_amount
				no_vat
				no_service_charge
				is_paid
			}
			cancelled_items {
				_id
				category
				item
				title
				original_price
				original_price_addons {
					vat {
						percentage
						amount
					}
					service_charge {
						percentage
						amount
					}
				}
				unit_price
				listed_price
				quantity
				extra_quantity
				serving_quantity
				comments
				cancelled_reason
				recipe {
					raw_material
					unit_of_measurement
					consumed_unit_of_measurement
					consumed_quantity
				}
				options {
					comments
					_id
					option
					uid
					title
					quantity
					original_price
					original_price_addons {
						vat {
							percentage
							amount
						}
						service_charge {
							percentage
							amount
						}
					}
					listed_price
					recipe {
						raw_material
						unit_of_measurement
						consumed_unit_of_measurement
						consumed_quantity
					}
				}
				type
				delivered_count
				status
				prep_time
				printer_tag
				subtotal
				subtotal_addons {
					offer {
						metadata
						amount
					}
					discount {
						is_divided
						type
						value
						amount
					}
					vat {
						is_included
						percentage
						amount
					}
					service_charge {
						is_included
						percentage
						amount
					}
					adjustment {
						amount
					}
				}
				net_amount
				gross_amount
				cancelled_amount
				no_vat
				no_service_charge
				is_paid
			}
			max_prepare_time
			items_count
			items_count_by_types
			prepped_count
			subtotal
			offer_amount
			discount_amount
			net_amount
			vat_amount
			service_charge_amount
			adjustment_amount
			grand_total
			gross_amount
			cancelled_amount
			receipt
			is_needing_prep_time_confirmation
			note
			is_cancelled
			is_bill_printed
			is_needing_payment_confirmation
			ticket_linebreaks

			_staff {
				_id
				first_name
				last_name
			}
			_prepared_by {
				_id
				first_name
				last_name
			}
			_processed_by {
				_id
				first_name
				last_name
			}
			_paid_by {
				_id
				first_name
				last_name
			}
			_cancelled_by {
				_id
				first_name
				last_name
			}
			_location {
				_id
				name
				type
				hash
			}
			_current_location {
				_id
				name
				type
				hash
			}
			_customer {
				_id
				first_name
				last_name
			}
			_receipt {
				_id
				payment_types {
					amount
					payment_type
					code
				}
			}
		}
	}
`;

export type GetOrderByIdResults = GetOrderByIdQuery['getOrderById'];

export const getOrderByIdQueryKey = (id: string) => ['getOrderById', id];

export const useGetOrderById = (id?: string, enabled?) => {
	const queryClient = useQueryClient();

	return useQuery({
		enabled: enabled ?? !!id,
		queryKey: getOrderByIdQueryKey(id!),
		queryFn: () => hubGqlClient.request(graphql(query), { id: id! }).then((res) => res.getOrderById),
		onSuccess: (data) => {
			queryClient.setQueryData<GetOperationalOrdersResults>(getOperationalOrdersKey(), (oldData) => {
				if (oldData) return regroupOrders(data, oldData);
				return initialData;
			});
		},
	});
};

export const useGetOrderByIdCache = (id?: string) => {
	return useGetOrderById(id, false);
};

export const useGetCurrentOrder = (enabled?: boolean) => {
	const {
		query: { id },
	} = useRouter();

	return useGetOrderById(id?.toString(), enabled);
};

export const useGetCurrentOrderCache = () => {
	return useGetCurrentOrder(false);
};
