import { useQuery } from '@tanstack/react-query';

import { hubGqlClient } from '~/libs/gql';
import { useLocationsIsLoading, useSetLocationsIsLoading } from '~/store';
import { graphql } from '~/types/__generated/gql';
import { GetOperationalLocationsQuery } from '~/types/__generated/gql/graphql';

const query = /* GraphQL */ `
	query GetOperationalLocations {
		getOperationalLocations {
			_id
			created_at
			updated_at
			venue
			name
			type
			payment_types
			status
			hash
			no_vat
			no_service_charge
			is_pay_later_allowed
			locations_groups_tags
			room_number
			default_order_note
			_current_orders {
				_id
				created_at
				updated_at
				index
				items {
					_id
					category
					item
					title
					original_price
					original_price_addons {
						vat {
							percentage
							amount
						}
						service_charge {
							percentage
							amount
						}
					}
					unit_price
					listed_price
					quantity
					extra_quantity
					serving_quantity
					comments
					cancelled_reason
					recipe {
						raw_material
						unit_of_measurement
						consumed_unit_of_measurement
						consumed_quantity
					}
					options {
						_id
						option
						uid
						title
						quantity
						original_price
						original_price_addons {
							vat {
								percentage
								amount
							}
							service_charge {
								percentage
								amount
							}
						}
						listed_price
						recipe {
							raw_material
							unit_of_measurement
							consumed_unit_of_measurement
							consumed_quantity
						}
					}
					type
					delivered_count
					status
					prep_time
					printer_tag
					subtotal
					subtotal_addons {
						offer {
							metadata
							amount
						}
						discount {
							is_divided
							type
							value
							amount
						}
						vat {
							is_included
							percentage
							amount
						}
						service_charge {
							is_included
							percentage
							amount
						}
						adjustment {
							amount
						}
					}
					net_amount
					gross_amount
					cancelled_amount
					no_vat
					no_service_charge
				}
				cancelled_items {
					_id
					category
					item
					title
					original_price
					original_price_addons {
						vat {
							percentage
							amount
						}
						service_charge {
							percentage
							amount
						}
					}
					unit_price
					listed_price
					quantity
					extra_quantity
					serving_quantity
					comments
					cancelled_reason
					recipe {
						raw_material
						unit_of_measurement
						consumed_unit_of_measurement
						consumed_quantity
					}
					options {
						_id
						option
						uid
						title
						quantity
						original_price
						original_price_addons {
							vat {
								percentage
								amount
							}
							service_charge {
								percentage
								amount
							}
						}
						listed_price
						recipe {
							raw_material
							unit_of_measurement
							consumed_unit_of_measurement
							consumed_quantity
						}
					}
					type
					delivered_count
					status
					prep_time
					printer_tag
					subtotal
					subtotal_addons {
						offer {
							metadata
							amount
						}
						discount {
							is_divided
							type
							value
							amount
						}
						vat {
							is_included
							percentage
							amount
						}
						service_charge {
							is_included
							percentage
							amount
						}
						adjustment {
							amount
						}
					}
					net_amount
					gross_amount
					cancelled_amount
					no_vat
					no_service_charge
				}
				prepared_at
				processed_at
				paid_at
				cancelled_at
				prepared_by
				processed_by
				paid_by
				cancelled_by
				user
				customer
				venue
				status
				is_paid
				payway_ref
				payway_verification_data
				staff
				original_orders
				cancelled_reason
				source
				device
				prep_time
				currencies_configs
				location
				current_location
				max_prepare_time
				items_count
				items_count_by_types
				prepped_count
				subtotal
				offer_amount
				discount_amount
				net_amount
				vat_amount
				service_charge_amount
				adjustment_amount
				grand_total
				gross_amount
				cancelled_amount
				receipt
				is_needing_prep_time_confirmation
				note
				is_cancelled
				is_bill_printed
				is_needing_payment_confirmation
			}
		}
	}
`;

export type GetOperationalLocationsResults = GetOperationalLocationsQuery['getOperationalLocations'];

export const getOperationalLocationsKey = () => ['getOperationalLocations'];

export const getOperationalLocationsQueryFn = () =>
	hubGqlClient.request(graphql(query)).then((res) => res.getOperationalLocations);

export const useGetOperationalLocations = (enabled?: boolean) => {
	const locationsIsLoading = useLocationsIsLoading();
	const setLocationsIsLoading = useSetLocationsIsLoading();

	return useQuery({
		enabled: enabled ?? true,
		queryKey: getOperationalLocationsKey(),
		queryFn: getOperationalLocationsQueryFn,
		onSuccess: () => {
			if (locationsIsLoading) setLocationsIsLoading(false);
		},
		initialData: [],
	});
};

export const useGetOperationalLocationsCache = () => {
	return useGetOperationalLocations(false);
};

export const getOperationalLocationsCache = () => {
	return (
		window.$queryClient.getQueryData<GetOperationalLocationsResults>(getOperationalLocationsKey(), {
			exact: true,
		}) || []
	);
};
